interface Data {
  name: string,
  region: string,
}
export const DATA: { [key: string]: Data } = {
  'us': {
    name: 'United States',
    region: 'North America',
  },
  'cn': {
    name: 'China',
    region: 'East Asia',
  },
  'singapore': {
    name: 'Singapore',
    region: 'Southeast Asia',
  },
  'uk': {
    name: 'United Kingdom',
    region: 'Northern Europe',
  },
  //   'au': {
  //     name: 'Australia',
  //     region: 'Oceania',
  //   },
  // 'hongkong': {
  //   name: 'Hong Kong',
  //   region: 'East Asia',
  // },
  //   'ca': {
  //     name: 'Canada',
  //     region: 'North America'
  //   },
  //   'germany': {
  //     name: 'Germany',
  //     region: 'Western Europe',
  //   },
  // 'japan': {
  //   name: 'Japan',
  //   region: 'East Asia',
  // },
  //   'korea': {
  //     name: 'South Korea',
  //     region: 'East Asia',
  //   },
  //   'india': {
  //     name: 'India',
  //     region: 'South Asia',
  //   },
  //   'italy': {
  //     name: 'Italy',
  //     region: 'Southern Europe',
  //   },
  // 'bahrain': {
  //   name: 'Bahrain',
  //   region: 'Middle East',
  // },
  // 'brazil': {
  //   name: 'Brazil',
  //   region: 'South America'
  // },
};

export const DOMAIN = 'deeper.io';

export const REGIONS = Object.keys(DATA);
