import { useEffect, useState } from "react";

interface MyIpProp {
  name: string;
  region: string;
  domain: string;
  api: string;
}

interface MyIpResponse {
  ip: string;
}

export default function MyIp({ region, domain, name, api }: MyIpProp) {
  const [ip, setIp] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetch(api)
      .then((resutl) => resutl.json())
      .then((result: MyIpResponse) => {
        // console.log(result);
        setIp(result.ip);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [api]);
  return (
    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
      <div className="p-3">
        <div className="card" style={{ width: "18rem" }}>
          <div className="card-header">
            {region} - {name}
          </div>
          <div className="card-body">
            <table className="table table-borderless">
              <tbody>
                <tr>
                  <td>Domain:</td>
                  <td style={{ textAlign: "right" }}>{domain}</td>
                </tr>
                <tr>
                  <td>IP:</td>
                  <td style={{ textAlign: "right" }}>
                    {loading ? (
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      ip
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
