import React from "react";
import "./App.css";
import MyIp from "./MyIp";
import { DATA, DOMAIN } from "./consts";

function App() {
  return (
    <>
      <div className="container overflow-hidden">
        <div className="row gy-5">
          {Object.keys(DATA).map((region) => (
            <MyIp
              key={region}
              name={DATA[region].name}
              region={DATA[region].region}
              domain={`${region}.${DOMAIN}`}
              api={`https://${region}.${DOMAIN}/v1/ip`}
            />
          ))}
        </div>
      </div>
      <footer className="footer mt-auto py-3 text-center">
        <div className="container">
          <p className="text-muted">Deeper Network © 2023</p>
        </div>
      </footer>
    </>
  );
}

export default App;
